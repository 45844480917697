import { createAsyncThunk } from '@reduxjs/toolkit'
import store from './store'

export const createPasswordToAccessFluidMonitor = createAsyncThunk(
  'data/createPasswordToAccessFluidMonitor',
  async () => {
    const token = store?.getState()?.user?.token
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token,
    }
    const url = `${process.env.REACT_APP_BACKEND_API_URL}/users/generate-app-password`
    let data
    let response
    try {
      response = await fetch(url, {
        headers,
        method: 'GET',
        crossDomain: true,
      })
    } catch (e) {
      console.log('### data/createPasswordToAccessFluidMonitor error:')
      // console.log(e)
      data = {
        isError: true,
        status: 500,
      }
      return data
    }
    console.log('### data/createPasswordToAccessFluidMonitor response.status')
    console.log(response.status)
    if (response?.ok === false) {
      data = {
        isError: true,
        status: response?.status,
      }
    } else {
      data = await response.json()
    }
    return data
  }
)

export const createLinkToDownloadIfUPdf = createAsyncThunk(
  'data/createLinkToDonloadIfUPdf',
  async (language) => {
    const token = store?.getState()?.user?.token
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token,
    }
    const url = `${process.env.REACT_APP_BACKEND_API_URL}/users/instructions-for-use-link?lang=${language}`
    let data
    let response
    try {
      response = await fetch(url, {
        headers,
        method: 'GET',
        crossDomain: true,
      })
    } catch (e) {
      console.log('### data/createLinkToDonloadIfUPdf error:')
      // console.log(e)
      data = {
        isError: true,
        status: 500,
      }
      return data
    }
    console.log('### data/createLinkToDonloadIfUPdf response.status')
    //console.log(response.status)
    if (response?.ok === false) {
      data = {
        isError: true,
        status: response?.status,
      }
    } else {
      data = await response.json()
    }
    return data
  }
)