import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { createLinkToDownloadIfUPdf } from '../../store/DataSlice'
import { RefreshIcon } from '@heroicons/react/solid'

const InstructionsForUse = ({
  children = null,
}) => {
  const [ pendingIfuUrl, setPendingIfuUrl ] = React.useState(false)
  const dispatch = useDispatch()
  const languagesList = ['de', 'fr', 'it', 'es'];

  const fetchPresignedUrl = async (language = 'en') => {
    setPendingIfuUrl(true)
    try {
      const response = await dispatch(createLinkToDownloadIfUPdf(language))
      setPendingIfuUrl(false)
      window.open(response?.payload?.url)
    } catch (error) {
      console.log(error)
      setPendingIfuUrl(false)
    }
  }

  const listItems = languagesList.map((language) =>
    (
      <section key={language}>
        <a
          onClick={() => fetchPresignedUrl(language)}
          className="mr-2 my-2 py-1 px-6 text-sm font-medium text-gray-900 bg-transparent cursor-pointer border-b-2 border-white hover:border-gray-300"
        >
          <span className="font-bold underline text-blue-500">{language.toUpperCase()}</span> [.pdf]
        </a>
      </section>
    )
  );

  return (
    <>
      <div as="nav" className="w-full">
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="mt-10 pt-5 pb-2 px-5 mx-auto bg-white w-full">
            <div className="flex flex-col w-full">
              <title className="flex justify-start text-2xl">Instruction for Use</title>
              <div className="flex justify-start my-4 text-left break-words">
                { (pendingIfuUrl)
                  ? (
                    <div className="flex justify-left items-left ml-20 rounded-lg bg-white bg-opacity-75 w-full h-full">
                      <RefreshIcon className="self-center text-gray-300 flip-horizontal w-14 h-14 bg-transparent rounded-md spin-back" />
                    </div>
                  )
                  : (
                    <section className="my-4 text-left break-words">
                      <div>
                        Please use the following link to
                        <a
                          className="px-1 underline text-blue-500 cursor-pointer"
                          onClick={() => fetchPresignedUrl()}
                        >
                          download
                        </a>
                        [.pdf]
                      </div>
                      <div className="inline-flex mt-6" role="group">
                        {listItems}
                      </div>
                    </section>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      {children}
    </>
  )
}

InstructionsForUse.propTypes = {
  children: PropTypes.any,
}

export default InstructionsForUse
