import React from 'react'
import PropTypes from 'prop-types'
import { classNames } from '../../utils/utils'

const Barcode128 = ({
  code,
  showCode = true,
  barcodeHeight = 40, // pixel
  className = '',
  fontStyle = {},
  alt = '',
  ...rest
}) => {
  const lookup = {}
  const data = '212222222122222221121223121322131222122213122312132212221213221312231212112232122132122231113222123122123221223211221132221231213212223112312131311222321122321221312212322112322211212123212321232121111323131123131321112313132113132311211313231113231311112133112331132131113123113321133121313121211331231131213113213311213131311123311321331121312113312311332111314111221411431111111224111422121124121421141122141221112214112412122114122411142112142211241211221114413111241112134111111242121142121241114212124112124211411212421112421211212141214121412121111143111341131141114113114311411113411311113141114131311141411131'.split(/(\d{6})/).filter(s => !!s)
  for (let i = 32; i < 127; i++) {
    lookup[String.fromCharCode(i)] = [i - 32, data[i - 32]]
  }
  const h = barcodeHeight // height
  const f = 2 // factor
  let svg = ''
  let x = 10 * f
  let sum = 104
  const draw = d => {
    d.split('').forEach((n, i) => {
      svg += (i % 2) ? '' : `<rect x="${x}" y="0" width="${(+n * f)}" height="${h}" />`
      x += +n * f
    })
  }
  draw('211214')
  code.split('').forEach(function (c, i) {
    const l = lookup[c] || [0, '']
    sum += l[0] * (i + 1)
    draw(l[1])
  })
  draw(data[sum % 103])
  draw('23311129')
  svg = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" height="${h}px" viewBox="0,0,${x + f},${h}">${svg}</svg>`

  return (
    <div className={classNames('flex flex-col', className)} {...rest}>
      <img src={`data:image/svg+xml;base64,${btoa(svg)}`} alt={alt} style={{ height: '100%', }}/>
      {showCode && <div style={fontStyle}>{code}</div>}
    </div>
  )
}

Barcode128.propTypes = {
  code: PropTypes.string.isRequired,
  showCode: PropTypes.bool,
  className: PropTypes.string,
  barcodeHeight: PropTypes.number,
  fontStyle: PropTypes.object,
  alt: PropTypes.string,
}

export default Barcode128
