import React from 'react'

import RetinSightLogoBall from '../../assets/svg/ris_logo_ball.svg'

const Footer = () => {
  return (
    <div className="w-full">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex items-center border-t-2 border-gray-300">
        <div className="leading-3 p-2 pt-4 w-full flex flex-col md:flex-row">
          <div className="flex justify-center md:justify-end w-full md:w-1/6">
            <img
              src={RetinSightLogoBall}
              className="h-40"
              alt="RetinSight • Fluid Monitor Customer Portal"
            />
          </div>
          <div className="flex flex-col md:flex-row text-left w-full md:w-5/6">
            <div className="text-left w-full md:w-3/6">
              <h1 className="text-xl ml-4 md:ml-20">Contact</h1>
              <div className="text-base m-5 md:ml-20">
                <ul>
                  <li>RetInSight GmbH</li>
                  <li>Elisabethstraße 13/1/13</li>
                  <li>1010 Vienna, Austria</li>
                  <li>support@retinsight.com</li>
                </ul>
              </div>
            </div>
            <div className="text-left w-full md:w-3/6">
              <h1 className="text-xl ml-4 md:ml-14">Links</h1>
              <div className="text-base m-5 md:ml-14">
                <ul className="footer-links">
                  <li><a href="https://retinsight.com/">Company Website</a></li>
                  <li><a href="/terms">Terms of Use</a></li>
                  <li><a href="/privacy-policy/en">Privacy Policy</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 pt-6 pb-6 ">
        <h1 className="text-sm text-gray-400">© 2022 RetInSight, All Rights Reserved</h1>
      </div>
    </div>
  )
}

export default Footer
