import React from 'react'
import PropTypes from 'prop-types'

const Terms = ({
  children = null,
}) => {
  return (
    <>
      <div className="w-full">
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="my-5 p-5 mx-auto bg-white w-full">
            <title className="flex justify-start text-3xl">Terms of Use</title>
            <div className="px-2 md:px-5 mx-auto bg-white w-full flex flex-col md:flex-row">
              <div className="text-left">
                <p>The Terms of Use for Fluid Monitor are covered in the User Agreement, which will be provided to you by RetInSight</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {children}
    </>
  )
}

Terms.propTypes = {
  children: PropTypes.any,
}

export default Terms
