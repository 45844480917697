import React from 'react'
import PropTypes from 'prop-types'
import ModalCreateFMPassword from './ModalCreateFMPassword'
// import ModalConfirmPasswordCreation from './ModalConfirmPasswordCreation'
import { useDispatch } from 'react-redux'
import { createPasswordToAccessFluidMonitor } from '../../store/DataSlice'
import ImgAccessFluidMonitor from '../../assets/accessFluidMonitor.png'

const FluidMonitor = ({
  children = null,
}) => {
  // const [ modalConfirmPasswordCreationIsOpen, setModalConfirmPasswordCreationIsOpen ] = React.useState(false)
  const [ modalCreateFMPasswordIsOpen, setModalCreateFMPasswordIsOpen ] = React.useState(false)
  const [ generatedTokensValues, setGeneratedTokensValues ] = React.useState({})
  const dispatch = useDispatch()

  const onConfirm = async () => {
    const result = await dispatch(createPasswordToAccessFluidMonitor())
    let response = { status: '', sub: '', appPassword: '' }
    if (result?.payload?.isError === true) {
      response.status = (result?.payload?.status === 401) ? 'unauthorized' : 'error'
    } else {
      if (result?.payload) {
        response = {
          status: result?.meta.requestStatus,
          sub: result?.payload.sub,
          appPassword: result?.payload.appPassword
        }
      }
    }
    setGeneratedTokensValues(response)
  }
  const onModalCancel = () => {
    setGeneratedTokensValues({})
    setModalCreateFMPasswordIsOpen(false)
  }

  return (
    <>
      <div className="w-full">
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 mb-6">
          <div className="mt-10 p-5 mx-auto bg-white w-full">
            <div className="w-full text-2xl">How to get access to Fluid Monitor?</div>
            <div className="mt-5 p2- md:p-5 mx-auto bg-white w-full flex flex-col md:flex-row">
              <div className="w-full md:w-1/3">
                <p className="text-left">
                  Please click the button ”Generate ID & Password” below in order to get your <strong>Customer ID + Customer 
                  Password</strong> for the Fluid Monitor Plugin in Heyex/Appway.<br/><br/>The credentials will be used to configure 
                  the RET plugin. Once the Customer ID and the Password are generated and displayed in the pop-up, please make sure 
                  to copy it by clicking on the icon. The copied Customer ID and the password will be used in Heyex 
                  for ”Configure App” settings.
                </p>
                <div className="visible md:invisible md:h-0 mt-4">
                  <img src={ImgAccessFluidMonitor} alt="Screen capture Access to Fluid Monitor app" className="mx-auto"/>
                </div>
                <div className="mt-4 py-3 md:p-3">
                  <button
                    onClick={() => setModalCreateFMPasswordIsOpen(true)}
                    className="w-60 px-4 p-2 bg-green-600 hover:bg-green-400 text-white rounded-md cursor-pointer"
                  >Generate ID & Password</button>
                </div>
              </div>
              <div className="w-full md:w-2/3 invisible md:visible h-0 md:h-auto">
                <img src={ImgAccessFluidMonitor} alt="Screen capture Access to Fluid Monitor app" className="mx-auto"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      {children}
      {/*
      <ModalConfirmPasswordCreation
        open={modalConfirmPasswordCreationIsOpen}
        onCancel={() => setModalConfirmPasswordCreationIsOpen(false)}
        onSuccess={() => onConfirm()}
        dispatch={dispatch}
      />
      */}
      <ModalCreateFMPassword
        open={modalCreateFMPasswordIsOpen}
        onOpen={modalCreateFMPasswordIsOpen}
        onCancel={onModalCancel}
        onConfirm={() => onConfirm()}
        // onSuccess={() => onConfirm()}
        data={generatedTokensValues}
        dispatch={dispatch}
      />
    </>
  )
}

FluidMonitor.propTypes = {
  children: PropTypes.any,
}

export default FluidMonitor
