import React from 'react'

const PrivacyDe = () => {
  return (
    <section>
      <div className="my-5 p-3 mx-auto bg-white w-full">
        <title className="flex justify-center text-3xl">Datenschutzinformation</title>
        <h2 className="flex justify-center text-xl">Informationspflicht gem. Art 13 und Art 14 DSGVO</h2>
        <div className="px-2 md:px-5 mx-auto bg-white w-full flex flex-col md:flex-row">
          <div className="text-left" aria-hidden="true">
            <p>Version vom 30/04/2022</p>
            <p>Der  Schutz  Ihrer  persönlichen  Daten  ist  uns  ein  besonderes  Anliegen.  Wir  verarbeiten  Ihre Daten  daher  ausschließlich  auf  Grundlage  der  gesetzlichen  Bestimmungen  (DSGVO,  TKG 2003).  In  diesen  Datenschutzinformationen  informieren  wir  Sie,  wie  wir  diesen  Schutz sicherstellen   und   welche   Arten   von   Daten   für   welchen   Zweck   verarbeitet   werden. Personenbezogene  Daten  bedeutet  jede  Information  mit  Bezug  zu  einer  identifizierten  oder identifizierbaren  natürlichen  Person,  wie  z.B.  Ihr  Name,  Ihre  Adresse,  E-Mail-Adressen, Nutzerverhalten.</p>

            <title className="flex justify-start mt-8 text-2xl">Verantwortliche</title>
            <p><span className="font-bold">RetInSight  GmbH</span>,  Elisabethstraße  13/1/13  in  1010  Wien,  Österreich,  CEO:  Corinna  zur Bonsen-Thomas, Handelsgericht Wien, FN 531348w, T: +43-(0)1-5813881, <a className="text-blue-600 underline" href="mailto: corinna.thomas@retinsight.com">corinna.thomas@retinsight.com</a> ist Verantwortliche für die Verarbeitung Ihrer personenbezogenen Daten gemäß Art. 4 Abs. 7 Datenschutz-Grundverordnung.</p>

            <title className="flex justify-start mt-8 text-2xl">Datenschutzbeauftragte</title>
            <p>RetInSight   GmbH   hat   die   Firma   <span className="font-bold">rs   consulting   GmbH</span>,   Buchberggasse   6   in   3400 Klosterneuburg,  Österreich,  CEO:  Roland  Schopper,  Firmenbuchgericht  Korneuburg,  FN 411839x, T:+43(2243)22562, <a className="text-blue-600 underline" href="mailto: datenschutz@resultate.at">datenschutz@resultate.at</a> als externen Datenschutzbeauftragte benannt.</p>

            <title className="flex justify-start mt-8 text-2xl">Datenkategorien und Zweck der Verarbeitung</title>
            <p>Die  Verantwortliche  erhebt  und  verarbeitet  je  nach  dem  Zweck  für  die  Verarbeitung verschiedene  Kategorien  personenbezogener  Daten.  In  den  folgenden  Abschnitten  werden die Datenkategorien nach dem Verarbeitungszweck dargestellt:</p>

            <title className="flex justify-start mt-8 text-xl font-bold flex flex-row">
              <div>
                3.1	Besuch unserer Website unter 
                <a href="www.retinsight.com" className="mx-2 underline" target="_blank">www.retinsight.com</a> oder 
                <a href="www.fluidmonitor-he.retinsight.cloud" className="mx-2 underline" target="_blank">www.fluidmonitor-he.retinsight.cloud</a>
              </div>
            </title>
            <title className="flex justify-start mt-4 ml-10 text-xl font-bold">3.1.1   Protokolldaten</title>
            <p>Wenn Sie unsere Website besuchen, erheben wir die folgenden personenbezogenen Daten, die von Ihrem Server übertragen werden:</p>
            <ul className="ml-10 list-disc">
              <li>IP-Adresse</li>
              <li>Datum und Uhrzeit der Anfrage</li>
              <li>Zeitzonendifferenz bezogen auf Greenwich Mean Time (GMT)</li>
              <li>Inhalt der Anfrage (spezifische Website)</li>
              <li>Zugangsstatus / HTTP-Status-Code</li>
              <li>Volumen der bei jedem Besuch übertragenen Daten</li>
              <li>Website, von der die Anfrage kommt</li>
              <li>Browser, einschließlich Sprache und Version</li>
              <li>Betriebssystem und Interface</li>
              <li>Herkunftsland des Besuchers</li>
            </ul>
            <title className="flex justify-start mt-4 ml-10 text-xl font-bold">3.1.2   Cookies unter www.retinsight.com</title>
            <p>Wenn  Sie  unsere  Website  besuchen,  werden  technisch  notwendige  Cookies  auf  Ihrem Computer gespeichert. Cookies sind kleine Text-Dateien, die in Verbindung mit dem von Ihnen genutzten Browser auf Ihrer Festplatte gespeichert werden. Sie dienen dazu, unser Angebot benutzerfreundlicher   und   effektiver   zu   gestalten.   Zusätzlich   und   entsprechend   der Informationen im „Cookie Bar“ können Sie Cookie(s) zu unserem Analyse System „Matamo“ annehmen oder ablehnen.</p>
            <title className="flex justify-start mt-4 ml-10 text-xl font-bold">3.1.3	Webanalyse</title>
            <p>Ihre Akzeptanz vorausgesetzt, verwenden wir das Analyse System „Matamo“ in der selbst gehosteten Version. Dadurch werden diesbezüglich keine Daten „in der Cloud“ verarbeitet und wir behalten die volle Kontrolle über den Schutz Ihrer personenbezogenen Daten.</p>
            <title className="flex justify-start mt-4 ml-10 text-xl font-bold">3.2	Ihr Kontakt mit uns</title>
            <p>Wenn Sie per Formular auf der Website oder per E-Mail-Kontakt mit uns aufnehmen, werden Ihre angegebenen Daten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen sechs Monate bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.</p>
            <title className="flex justify-start mt-4 ml-10 text-xl font-bold">3.3	Vertragsabwicklung</title>
            <p>Zum Zweck der Vertragsabwicklung werden folgende personenbezogene Daten bei uns gespeichert: Ansprechpartner, E-Mail-Adresse, Zugangsdaten, Telefonnummer, Verträge und sonstige Auftragsunterlagen. Die von Ihnen bereit gestellten Daten sind zur Vertragserfüllung bzw. zur Durchführung vorvertraglicher Maßnahmen erforderlich. Ohne diese Daten können wir den Vertrag mit Ihnen nicht abschließen.</p>
            <title className="flex justify-start mt-4 ml-10 text-xl font-bold">3.4	MitarbeiterInnendaten</title>
            <p>Die Details zur Verarbeitung der Daten unserer MitarbeiterInnen sind für diese im Dokument „Mitarbeiter Datenschutzerklärung“ ersichtlich.</p>
            <title className="flex justify-start mt-4 ml-10 text-xl font-bold">3.5	Biometrische Daten in Form von OCT-Scans</title>
            <p>Im Zuge der Vertragsabwicklung verarbeiten wir als Auftragsverarbeiter biometrische Daten in Form von OCT-Scans der Netzhaut. Diese Daten werden immer in pseudonymisierter Form an uns übermitteln.</p>
            <p>Mit dem Privileg, personenbezogene Daten von Personen zu verarbeiten, geht die Verantwortung einher, diese Daten ethisch korrekt zu behandeln. Wir haben uns verpflichtet, Daten verantwortungsbewusst und transparent zu verwenden. RetInSight ist sehr bemüht, in allen Bereichen des Unternehmens hohe ethische Standards einzuhalten und eine ethische, werteorientierte Kultur zu betreiben, in der auf Probleme schnell und sichtbar reagiert wird. 
              RetInSight behandelt alle Patientendaten gemäß nationalen und internationalen Gesetzen und Richtlinien sowie ethischen Standards und Grundsätzen.
            </p>
            <p>RetInSight verarbeitet Bilddaten, die als sensible personenbezogene Daten eingestuft werden, da über die OCT-Aufnahmen Rückschlüsse auf den Gesundheitszustand der Person gezogen werden können. Der Schutz der Privatsphäre ist sowohl aus regulatorischer Sicht als auch für die Kunden und Kooperationspartner von RetInSight ein zentrales und sensibles Thema. Netzhautbilder werden als biometrische Daten kategorisiert, da die eindeutige Gefäßstruktur nicht geändert werden kann (ähnlich einem Fingerabdruck) und daher nach aktueller Interpretation nur pseudonymisiert (nicht anonymisiert) werden kann. RetInSight ist sich bewusst, dass die Akzeptanz der Technologie für künstliche Intelligenz (KI) durch die Kunden von entscheidender Bedeutung ist, und entwickelt und betreibt diese Algorithmen gemäß den EU-Ethikrichtlinien für vertrauenswürdige KI.</p>
            <p>Pseudonymisierung bedeutet, dass das Unternehmen die gesammelten Daten nicht bestimmten Patienten zuordnen kann, diese personenbezogenen Daten (PII) jedoch beim direkten Gesundheitsdienstleister verbleiben. Darüber hinaus wird bereits eine vollständige Verschlüsselung in den Entwicklungsprozess einbezogen.</p>
            <p>Durch strenge Technische und Organisatorische Maßnahmen infolge unserer Datenschutzfolgenabschätzung wird ein Höchstmaß an Privacy und Security sichergestellt. Alle Daten werden ausschließlich verschlüsselt und nur innerhalb der EU verarbeitet. Die Datensätze sind von Anfang an pseudonymisiert und nur eine beschränkte Anzahl an MitarbeiterInnen, die unbedingt notwendig sind, können auf diese Daten zugreifen.</p>
            <p>Wir sind uns unserer besonderen Verantwortung bewusst und legen strengste Maßstäbe an, um ein Höchstmaß an Sicherheit und Datenschutz zu ermöglichen.</p>
            <title className="flex justify-start mt-8 text-2xl">Zweck und Rechtsgrundlage der Datenverarbeitung</title>
            <title className="flex justify-start mt-8 text-xl font-bold flex flex-row">4.1	Besuch unserer Website</title>
            <p>Wir möchten sicherstellen, dass Sie auf allen Bereichen unserer Website nutzerfreundlich unterwegs sind, insbesondere, dass Sie keine technischen Störungen oder Funktionsfehler erleben. Wir wollen zudem sicherstellen, dass wir Sie mit den für Sie relevantesten Informationen versorgen, was es erforderlich machen kann, dass wir gewisse personenbezogene Daten berücksichtigen, wie etwa die Sprache in Ihren Browser-Einstellungen oder das Land, von dem aus Sie unsere Dienste besuchen. Die rechtliche Grundlage zur dargestellten Verarbeitung von personenbezogenen Daten, ist unser berechtigtes Interesse, d.h. Art. 6 Abs. 1 lit. f) DS-GVO.</p>
            <title className="flex justify-start mt-8 text-xl font-bold flex flex-row">4.2	Ihr Kontakt mit uns</title>
            <p>Wenn Sie uns kontaktieren, verarbeiten wir Ihre personenbezogenen Daten, um Ihre Anfrage zu beantworten. Die rechtliche Grundlage zur Verarbeitung Ihrer personenbezogenen Daten ist die Anbahnung eines Vertragsverhältnisses auf Grundlage Ihrer Anfrage oder eines bestehenden vertraglichen Verhältnisses mit Ihnen und damit Art. 6 Abs. 1 b) der DSGVO bzw. die Wahrung unserer berechtigten Interessen, also Art. 6 Abs. 1 lit. f) DSGVO.</p>
            <title className="flex justify-start mt-8 text-xl font-bold flex flex-row">4.3	Vertragsabwicklung</title>
            <p>Die Datenverarbeitung ist notwendig zur Vertragserfüllung und erfolgt daher auf Basis der gesetzlichen Bestimmungen des Art 6 Abs 1 lit b) der DSGVO.</p>
            <title className="flex justify-start mt-8 text-xl font-bold flex flex-row">4.4	Ihre Zustimmungserklärung zu Cookie(s)</title>
            <p>Die Datenverarbeitung erfolgt im Falle Ihrer Zustimmung im „Cookie Bar“ bei Ihrem ersten Besuch gemäß dem Artikel 6 Abs. 1 lit a) der DSGVO.</p>
            <title className="flex justify-start mt-8 text-2xl">Empfänger</title>
            <p>Eine Datenübermittlung an Dritte erfolgt nicht, mit Ausnahme der abwickelnden Bankinstitute/Zahlungsdienstleister zum Zwecke der Abwicklung des Zahlungsverkehrs, an das von uns beauftragte Transportunternehmen/Versandunternehmen zur Zustellung eventueller Unterlagen sowie an unseren Steuerberater zur Erfüllung unserer steuerrechtlichen Verpflichtungen. In speziellen Bereichen arbeiten wir mit ausgewählten Auftragsverarbeitern zusammen, mit welchen wir entsprechende Vereinbarungen gemäß DSGVO getroffen haben.</p>
            <title className="flex justify-start mt-8 text-2xl">Speicherfristen für personenbezogene Daten</title>
            <p>Ihre Daten werden gelöscht, sobald Sie Ihre Einwilligung widerrufen bzw. allgemein, sobald der Zweck der Datenverarbeitung weggefallen ist. In manchen Fällen sind wir gesetzlich verpflichtet, Daten für eine gewisse Zeit zu behalten. Sobald dieser Zeitraum verstrichen ist, löschen wir diese Daten in Übereinstimmung mit den datenschutzrechtlichen Verpflichtungen.</p>
            <title className="flex justify-start mt-8 text-2xl">Social-Media Plug-Ins</title>
            <p>Aus Datenschutzgründen verwenden wir derzeit keine Social-Media Plug-Ins.</p>
            <title className="flex justify-start mt-8 text-2xl">Integrierte Service-Tools von Dritten</title>
            <p>Unsere Website beinhaltet keine Service-Tools von Dritten.</p>
            <title className="flex justify-start mt-8 text-2xl">Ihre Rechte</title>
            <p>Ihnen stehen bezüglich Ihrer bei uns gespeicherten Daten grundsätzlich die Rechte auf Auskunft, Berichtigung, Löschung, Einschränkung, Datenübertragbarkeit, Widerruf und Widerspruch zu. Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich bei der uns oder der Datenschutzbehörde beschweren, in Österreich ist dies die Datenschutzbehörde unter <a className="text-blue-600 underline" href="www.dsb.gv.at" target="_blank">www.dsb.gv.at</a>.</p>
            <p>Unbeschadet Ihrer Rechte, erreichen Sie uns für etwaige Rückfragen oder Anregungen unter den oben (unter Punkt „Verantwortliche“ oder „Datenschutzbeauftragte“) angeführten Kontakt Details! Wir freuen uns auf Ihre Nachricht!</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PrivacyDe