import { createSlice } from '@reduxjs/toolkit'
// import {
//   Auth,
//   // API
// } from 'aws-amplify'
// import { useDispatch } from 'react-redux'
/*

API:

"/app/user", POST:
{
  email: <value>
}
"/internal", GET: return "hello world"

"/app/ping", GET: return "pong"
"/app/pingdb", GET: return json stuff

*/

const initialState = {
  token: null,
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: '',
  data: {
    email: null,
  },
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload
    },
    resetToken: (state) => {
      // TODO: sign out?
      state.token = null
      sessionStorage.clear()
    },
    setUserEmailAddress: (state, action) => {
      state.data.email = action.payload
    },
    resetUser: (state) => {
      state.data = {
        email: null,
      }
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  setToken,
  resetToken,
  setUserEmailAddress,
  resetUser,
} = userSlice.actions

export default userSlice.reducer
