import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import {
  // BellIcon,
  MenuIcon,
  XIcon,
  UserIcon,
  GlobeIcon,
  // CalendarIcon,
  DocumentTextIcon,
  LockOpenIcon,
  LogoutIcon,
  // AdjustmentsIcon,
} from '@heroicons/react/outline'
import { resetToken } from '../auth/store/userSlice'
import { Link, useLocation } from 'react-router-dom'
import RetinSightLogo from '../../assets/svg/ris_logo_color_negative.svg'

const classNames = (...classes) => classes.filter(Boolean).join(' ')

// const Header = ({
//   userdata: {
//     first_name,
//     last_name,
//   },
// }) => {
const Header = ({
  userData: {
    email = null
  } = null,
}) => {
  const location = useLocation()
  const dispatch = useDispatch()
  const history = useHistory();

  const navigation = [
    { name: 'Instruction for Use', linkTo: '/instructions', cssClass: 'text-gray-300 mx-2' },
    { name: 'Get Access to Fluid Monitor', linkTo: '/fluid-monitor', cssClass: 'text-yellow-200 mx-7' },
  ]
  const navigationForMobile = [
    {
      name: 'Instruction for Use',
      icon: DocumentTextIcon,
      testId:'submenuMobileIfU',
      onClick: () => { history.push('/instructions')}
    },
    {
      name: 'Get Access to Fluid Monitor',
      icon: LockOpenIcon, testId:'submenuMobileAccessToFM',
      onClick: () => { history.push('/fluid-monitor') }
    },
    {
      name: 'Sign out',
      icon: LogoutIcon,
      testId:'submenuMobileLogout',
      onClick: () => { dispatch(resetToken()) }
    },
  ]
  const profile = [
    { name: 'Sign out', icon: LogoutIcon, testId:'menuLogout', onClick: () => {
      dispatch(resetToken())
    }
    },
  ]

  if (email) {
    // add the email of the user to the array at 1st position
    navigationForMobile.unshift(
      { name: `${email}`, icon: UserIcon, testId:'menuUser', },
    )
    profile.unshift(
      { name: `${email}`, icon: UserIcon, testId:'menuUser', },
    )
  }
  const displaySubmenuButton = (active, item) => {
    return (
      <button
        className={classNames(
          active ? 'bg-gray-100' : '',
          (item.icon === UserIcon) ? 'border-b' : '',
          'w-full block px-4 py-2 text-sm text-gray-700 flex items-center'
        )}
        onClick={item.onClick}
        data-testid={item.testId || ''}
      > 
        <item.icon
          className={classNames(
            'text-gray-400 group-hover:text-gray-300',
            'mr-1 flex-shrink-0 h-6 w-6'
          )}
          aria-hidden="true"
        />
        <span className="pl-2 whitespace-nowrap">{item.name}</span>
      </button>
    )
  }

  return (
    <>
      <Disclosure as="nav" className="bg-gray-800 w-full">
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex items-center justify-between h-16">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <Link key="Home" to="/">
                      <img
                        src={RetinSightLogo}
                        className="h-14 w-30"
                        alt="RetinSight • Fluid Monitor Customer Portal"
                      />
                    </Link>
                  </div>
                  <div className="flex items-center justify-between hidden md:block">
                    <div className="ml-10 flex items-baseline text-lg">
                      {navigation.map((item) => {
                        const isCurrentLocation = location.pathname === item.linkTo
                        return (
                          <Link
                            key={item.name}
                            to={item.linkTo}
                            className={classNames(
                              item.cssClass,
                              isCurrentLocation
                                ? 'border-b-2 border-gray-300'
                                : 'border-b-2 border-transparent'
                              ,'p-1 border-b-2 border-transparent hover:text-white'
                            )}
                          >
                            {item.name}
                          </Link>
                        )}
                      )}
                    </div>
                  </div>
                </div>
                <div className="hidden md:block">
                  <div className="ml-4 flex items-center md:ml-6">

                    {/* Profile dropdown */}
                    <Menu as="div" className="ml-3 relative">
                      {({ open }) => (
                        <>
                          <div className="flex items-center">
                            <Menu.Button
                              className="bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                              data-testid="buttonMenuUser"
                            >
                              <span className="sr-only">Open user menu</span>
                              <UserIcon className="h-6 w-6" aria-hidden="true" />
                            </Menu.Button>
                          </div>
                          <Transition
                            show={open}
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items
                              static
                              className="origin-top-right absolute right-0 mt-2 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                            >
                              {profile.map((item) => (
                                <Menu.Item key={item.name}>
                                  {({ active }) => (
                                    displaySubmenuButton(active, item)
                                  )}
                                </Menu.Item>
                              ))}
                            </Menu.Items>
                          </Transition>
                        </>
                      )}
                    </Menu>
                  </div>
                </div>
                <div className="-mr-2 flex md:hidden">

                  {/* Mobile menu button */}
                  <Menu as="div" className="ml-3 relative">
                    {({ open }) => (
                      <>
                        <div className="flex items-center">
                          <Menu.Button
                            className="bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                            data-testid="buttonMenuUser"
                          >
                            <span className="sr-only">Open user menu for mobile</span>
                            {open ? (
                              <XIcon className="block h-6 w-6" aria-hidden="true" />
                            ) : (
                              <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                            )}
                          </Menu.Button>
                        </div>
                        <Transition
                          show={open}
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items
                            static
                            className="origin-top-right absolute right-0 mt-2 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                          >
                            { navigationForMobile.map((item) => (
                              <Menu.Item key={item.name}>
                                {({ active }) => (
                                  displaySubmenuButton(active, item)
                                )}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Transition>
                      </>
                    )}
                  </Menu>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="md:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                {navigation.map((item, itemIdx) =>
                  itemIdx === 0 ? (
                    <Fragment key={item}>
                      {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */}
                      <a href="#" className="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium">
                        {item}
                      </a>
                    </Fragment>
                  ) : (
                    <a
                      key={item}
                      href="#"
                      className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                    >
                      {item}
                    </a>
                  )
                )}
              </div>
              <div className="pt-4 pb-3 border-t border-gray-700">
                <div className="flex items-center px-5">
                  <div className="flex-shrink-0">
                    <UserIcon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium leading-none text-white">Hans Mustermann</div>
                    <div className="text-sm font-medium leading-none text-gray-400">hans@example.com</div>
                  </div>
                  <button className="ml-auto bg-gray-800 flex-shrink-0 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                    <span className="sr-only">Change language</span>
                    <GlobeIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-3 px-2 space-y-1">
                  {profile.map((item) => (
                    <a
                      key={item}
                      href="#"
                      className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
                    >
                      {item}
                    </a>
                  ))}
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  )
}

Header.propTypes = {
  userData: PropTypes.shape({
    email: PropTypes.string,
  }),
}

export default Header
